import request from '@/network/request.js'
/**
 * 获取测试分类列表
 */
export function lists_judge(data) {
	return request({
		url: '/admin/interest_judge/lists_judge',
		method: 'post',
		data,
	})
}

/**
 * 增加|修改类型分类
 */
export function add_up_judge(data) {
	return request({
		url: '/admin/interest_judge/add_up_judge',
		method: 'post',
		data,
	})
}

/**
 * 删除|批量类型分类
 */
export function dels_judge(data) {
	return request({
		url: '/admin/interest_judge/dels_judge',
		method: 'post',
		data,
	})
}



